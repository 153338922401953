<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <Header :event="event"/>
      <v-card-title class="headline">Microsoft Teams Integration for {{event.name}}</v-card-title>

      <v-card-text>      
        <p>
          Connect this event with Microsoft Teams.
        </p>

        <v-alert v-if="isConnectedWithTeams && !event.published" type="info">
          <p>This event is not yet published. Publish your event before you can start.</p>
          <p>
            <v-btn :to="{name: 'eventmanagerView', params: {id:event.id}}">View and Publish</v-btn>
          </p>
        </v-alert>

        <v-alert v-if="connectedWithTeamsChannel" type="success">
          <p>This event is connected with Microsoft Teams channel <strong>{{connectedWithTeamsChannelName}}</strong>.</p>
          <p v-if="false && event.published"><v-btn @click="syncNow">Full Sync Now</v-btn></p>
          <p>
            <v-btn outlined @click="disconnect">Disconnect</v-btn>
          </p>
        </v-alert>
        <v-alert v-else-if="connectedWithTeamsChat" type="success">
          <p>This event is connected with Microsoft Teams chat <strong>{{connectedWithTeamsChatTopic}}</strong>.</p>
          <p v-if="false && event.published"><v-btn @click="syncNow">Full Sync Now</v-btn></p>
          <p>
            <v-btn outlined @click="disconnect">Disconnect</v-btn>
          </p>
        </v-alert>
        <div v-else>
          <v-alert type="info">
            <p>This event is not yet connected with Microsoft Teams.</p>
          </v-alert>
          <h3>Connect with Microsoft Teams</h3>
          <p>Open Microsoft Teams and add our app to either a channel or chat to get started.</p>
        </div>

      </v-card-text>

    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import Header from './_Header.vue'

export default {
  name: "IntegrationsEventsCom",
  components: {
    Header,
  },
  props: {
  },
  data() {
    return {
      event: null,
      provider: null,
      siteData: siteData,
    };
  },
  async mounted() {
    await this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });
  },
  methods: {
    async getEvent(id) {
      this.event = (await eventManagerService.get(id)).data;
    },

    async loadData() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },

    async syncNow() {
      const response = (await eventManagerService.integrationsTeamsSync(this.event.id)).data;
      await this.loadData();
      this.$helpers.toastResponse(this, response, 'Successfully synced registration data.');
    },

    async disconnect() {
      if (confirm('Are you sure you want to disconnect this event from Microsoft Teams?')) {
        const response = (await eventManagerService.unlinkFromTeams(this.event.id)).data;
        await this.loadData();
        this.$helpers.toastResponse(this, response, 'Successfully disconnected from Microsoft Teams.');
      }
    },


  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Integrations', exact: true, to: { name: 'eventmanagerIntegrations', params: {id: this.event.id}} },
        { text: `Microsoft Teams Integration`, disabled: true },
      ];
    },
    connectedWithTeamsChannel() {
      return (this.event && this.event.meta && this.event.meta.find(x => x.key == 'teams.channel_id')||{}).val_str;
    },
    connectedWithTeamsChat() {
      return (this.event && this.event.meta && this.event.meta.find(x => x.key == 'teams.chat_id')||{}).val_str;
    },
    connectedWithTeamsChannelName() {
      return (this.event && this.event.meta && this.event.meta.find(x => x.key == 'teams.channel_name')||{}).val_str;
    },
    connectedWithTeamsChatTopic() {
      return (this.event && this.event.meta && this.event.meta.find(x => x.key == 'teams.chat_topic')||{}).val_str;
    },
    isConnectedWithTeams() {
      return this.event && this.event.meta && this.event.meta.some(x => x.key == 'teams.tenant_id');
    },
    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

